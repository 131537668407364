import axios, { AxiosResponse } from "axios";

import { Quotation, QuotationResponse } from "../type/Quotation";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfUser(): Promise<Quotation[]> {
  const response: AxiosResponse<QuotationResponse> = await axios.get(
    `${API_URL}/get-all-quotations`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.requestedQuotation) {
    return response.data.requestedQuotation;
  } else {
    return [];
  }
}

export async function getSingleQuotation(id: number) {
  const requestBody = { id: id }; // Create the request body with the ID
  const response = await axios.post(
    `${API_URL}/get-quotations-details`,
    { id: requestBody.id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.quotation_detail;
}
