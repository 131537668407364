import { Link } from "react-router-dom";

import { Quotation } from "../../type/Quotation";

import getListOfQuotation from "../../services/quotationService";
import { useEffect, useState } from "react";

import Loader from "../../fragments/Loader";

import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { useLanguage } from "../../context/LanguageContext";

function ListQuotation() {
  const [quotation, setQuotation] = useState<Quotation[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [successMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const { translations } = useLanguage();

  async function init() {
    const userList = await getListOfQuotation();
    setQuotation(userList);
    const totalCount = userList.length;
    setTotalCount(totalCount);
    setError("");
    setLoader(false);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>

        <div className="col-md-8">
          <div className="card mt-10">
            <div className="card-body">
              <div className="d-md-flex gap-4 align-items-center">
                <div className="d-none d-md-flex">
                  {translations.TOTAL} {totalCount} {translations.QUOTATION}
                </div>
                <div className="d-md-flex gap-4 align-items-center">
                  <form className="mb-3 mb-md-0"></form>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loader ? (
              <Loader />
            ) : (
              <table
                className="table table-custom table-lg mb-0"
                id="Categorys"
              >
                <thead>
                  <tr>
                    <th>{translations.ID}</th>
                    <th>{translations.PRODUCT_ID} </th>
                    <th>{translations.USER_ID} </th>
                    <th>{translations.DESCRIPTION}</th>
                    <th>{translations.QUANTITY}</th>
                    <th>{translations.ADDRESS}</th>
                    <th>{translations.DATE}</th>
                    <th>{translations.TIME}</th>
                    <th>{translations.PLACE}</th>
                    <th>{translations.DISABLE}</th>
                    <th>{translations.PRODUCT_NAME}</th>
                    <th>{translations.ACTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {quotation.length > 0
                    ? quotation.map((output, index) => (
                        <tr key={index}>
                          <td>{output.id}</td>
                          <td>{output.product_id}</td>
                          <td>{output.user_id}</td>
                          <td>{output.desc}</td>
                          <td>{output.quantity}</td>
                          <td>{output.address}</td>
                          <td>{output.date}</td>
                          <td>{output.time}</td>
                          <td>{output.place}</td>
                          <td>{output.disable}</td>
                          <td>{output.product.product_name} </td>
                          <td className="text-end">
                            <div className="d-flex">
                              <Link to={`/quotation-details/${output.id}`}>
                                <button className="btn btn-primary btn-sm">
                                  <b>{translations.VIEW_QUOTATION}</b>
                                </button>
                                {/* <i className="bi bi-pencil small me-2 customIcon mx-3"></i> */}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Records Found"}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListQuotation;
