import React, { createContext, useContext, useState } from "react";

interface AuthContextType {
  logout: () => void;
  setToken?: (token: string) => void;
  isLoggedIn: boolean;
  setLoggedIn: (isLoggedIn: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  setToken: () => {},
  isLoggedIn: false,
  setLoggedIn: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>("");

  const logout = () => {
    setLoggedIn(false);
  };

  const authContextValue = {
    isLoggedIn,
    setLoggedIn,
    logout,
    token,
    setToken,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
