import { useLanguage } from "../../context/LanguageContext";

function SortOrder({
  handleSelect,
  selectedOption,
}: {
  handleSelect: (e: any) => any;
  selectedOption: string;
}) {
  const { translations } = useLanguage();
  return (
    <div className="col-md-12">
      <select
        onChange={(e) => handleSelect(e)}
        name="kt_ecommerce_products_table_length"
        aria-controls="kt_ecommerce_products_table"
        defaultValue={selectedOption}
        className="form-select "
        id="form-selectcustom"
      >
        <option>{translations.Sort_By}</option>
        <option value="In-Process">{translations.IN_PROCESS}</option>
        <option value="Accepted">{translations.ACCEPTED}</option>

        <option value="Completed">{translations.COMPLETED}</option>
        <option value="Rejected">{translations.REJECTED}</option>
        <option value="Delivery">{translations.DELIVERY}</option>
      </select>
    </div>
  );
}

export default SortOrder;
