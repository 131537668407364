import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import AuthContextProvider from "./context/authContext";
import { LanguageProvider } from "./context/LanguageContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
