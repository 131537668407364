import { Link } from "react-router-dom";

import { Category } from "../../type/Category";

import getListOfCategory from "../../services/categoryService";
import { useEffect, useState } from "react";

import Loader from "../../fragments/Loader";

import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import Language from "../../fragments/Language";
import { useLanguage } from "../../context/LanguageContext";

function ListCategory() {
  const [category, setCategory] = useState<Category[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [successMessage, setSuccess] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const { translations } = useLanguage();

  async function init() {
    const categoryList = await getListOfCategory();
    setCategory(categoryList);
    const totalCount = categoryList.length;
    setTotalCount(totalCount);
    setError("");
    setLoader(false);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>

        <div className="col-md-8">
          <div className="card mt-10">
            <div className="card-body ">
              <div className="d-md-flex gap-4 align-items-center">
                <div className="d-none d-md-flex">
                  {translations.TOTAL} {totalCount} {translations.CATEGORY}
                </div>
                <div className="d-md-flex gap-4 align-items-center">
                  <form className="mb-3 mb-md-0"></form>
                </div>

                <div className=" " style={{ marginLeft: "667px" }}>
                  <Link
                    to="/category/add"
                    className="btn btn-primary "
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {translations.ADD_CATEGORY}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loader ? (
              <Loader />
            ) : (
              <table
                className="table table-custom table-lg mb-0"
                id="Categorys"
              >
                <thead>
                  <tr>
                    <th>{translations.ID}</th>
                    <th>{translations.IMAGE}</th>
                    <th>{translations.NAME}</th>

                    <th>{translations.ACTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {category.length > 0
                    ? category.map((output, index) => (
                        <tr key={index}>
                          <td>{output.id}</td>
                          <td>
                            <img
                              src={output.image_path}
                              alt="category"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "10px",
                              }}
                            />
                          </td>
                          <td>{output.name}</td>

                          <td className="text-end">
                            <div className="d-flex">
                              <Link to={`/category/${output.id}`}>
                                <button className="btn btn-primary btn-sm">
                                  <b>{translations.UPDATE}</b>
                                </button>
                                {/* <i className="bi bi-pencil small me-2 customIcon mx-3"></i> */}
                              </Link>
                              <Link to={`/product/${output.id}`}>
                                <button className="btn btn-primary btn-sm mx-3">
                                  <b>{translations.VIEW_PRODUCT}</b>
                                </button>
                                {/* <i className="bi bi-pencil small me-2 customIcon mx-3"></i> */}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Records Found"}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListCategory;
