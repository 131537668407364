import Sidebar from "./Sidebar";

function Layout() {
  return (
    <>
      <div className="d-flex flex-column flex-root app-root " id="kt_app_root">
        <div
          className="app-page  flex-column flex-column-fluid "
          id="kt_app_page"
        >
          <div
            className="app-wrapper  flex-column flex-row-fluid "
            id="kt_app_wrapper"
          >
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
export default Layout;
