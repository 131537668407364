import React from "react";
import { useLanguage } from "../context/LanguageContext";

function Language() {
  const { language, changeLanguage } = useLanguage();

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
  };

  return (
    <div>
      <div className="col-md-10 mt-4 d-flex align-item-end">
        <select
          name="kt_ecommerce_products_table_length"
          aria-controls="kt_ecommerce_products_table"
          className="form-select"
          id="form-selectcustom2"
          value={language}
          onChange={(e) => handleLanguageChange(e.target.value)}
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </select>
      </div>
    </div>
  );
}

export default Language;
