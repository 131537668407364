import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";
import { getSingleQuotation } from "../../services/quotationService";
import { Quotation } from "../../type/Quotation";

import { useLanguage } from "../../context/LanguageContext";

function QuotationDetail() {
  const [quotationData, setQuotationData] = useState<Quotation | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { translations } = useLanguage();
  const { id } = useParams();

  useEffect(() => {
    async function fetchQuotation() {
      try {
        setLoading(true);
        const response = await getSingleQuotation(Number(id));
        setQuotationData(response);
        console.log("response");
        console.log(response.product);
      } catch (err) {
        setError("An error occurred while fetching category details.");
      } finally {
        setLoading(false);
      }
    }

    fetchQuotation();
  }, [id]);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className="col-md-8">
          <h2 className="mt-10 mb-15">{translations.QUOTATION_DETAILS}</h2>

          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loading ? (
              <p>{translations.LOADING_CATEGORY}...</p>
            ) : error ? (
              <p>{error}</p>
            ) : quotationData ? (
              <div className="row g-4 mb-3">
                <div className="col-md-12">
                  <div className="my-2">
                    {/* <label className="pb-3">
                      <b> {translations.PRODUCT_PICTURE}:</b>{" "}
                        <img
                        src={`http://qoutem.com:3000/product-images/${quotationData.product.images[0].image_url}}`}
                        alt="profile picture"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                      />
                    </label> */}
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.PRODUCT_DETAILS}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b> {translations.PRODUCT_ID}:</b> {quotationData.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.CATEGORY_ID}: </b>{" "}
                      {quotationData.product.category_id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PRODUCT_NAME}: </b>{" "}
                      {quotationData.product.product_name}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DESCRIPTION}: </b>{" "}
                      {quotationData.product.description}
                    </label>
                  </div>
                </div>
                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.USER_DETAILS}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.USER_ID}: </b> {quotationData.user.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PHONE_NUMBER}: </b>{" "}
                      {quotationData.user.phone_number}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.FULL_NAME}: </b>{" "}
                      {quotationData.user.full_name}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.EMAIL}: </b> {quotationData.user.email}
                    </label>
                  </div>
                </div>
                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.QUOTATION_DETAILS}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.Quotation_Id}: </b> {quotationData.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.QUOTATION_DESC}: </b>{" "}
                      {quotationData.desc}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.QUANTITY}: </b> {quotationData.quantity}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.ADDRESS}: </b> {quotationData.address}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DATE}: </b>
                      {quotationData.date}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.TIME}: </b> {quotationData.time}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PLACE}: </b> {quotationData.place}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DISABLE}: </b>
                      {quotationData.disable}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <p>{translations.NO_QUOTATION_FOUND}</p>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default QuotationDetail;
