import { useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

import Language from "./Language";
import { useLanguage } from "../context/LanguageContext";

function AppSidebar() {
  useEffect(() => {}, []);
  const cookies = new Cookies();
  const { translations } = useLanguage();

  function logout() {
    localStorage.removeItem("token");
    cookies.remove("token", { path: "/" });
    window.location.href = "/";
  }

  return (
    <>
      <div id="kt_app_sidebar" className="app-sidebar  flex-column ">
        <Sidebar>
          <Menu>
            <Language />
            <div
              className="my-3 app-sidebar-logo px-6"
              id="kt_app_sidebar_logo"
            >
              <h3>{translations.QUOTEM}</h3>

              {/* <img
                alt="Logo"
                // src="https://dev-pdm.cargonerd.com/assets/images/logo.png"
                className="h-40px app-sidebar-logo-minimize"
              /> */}
            </div>

            <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
              <div
                id="kt_app_sidebar_menu_wrapper"
                className="app-sidebar-wrapper"
              >
                <div
                  id="kt_app_sidebar_menu_scroll"
                  className="scroll-y my-5 mx-3"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="true"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                  data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                  data-kt-scroll-offset="5px"
                  data-kt-scroll-save-state="true"
                >
                  <div
                    className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6"
                    id="#kt_app_sidebar_menu"
                    data-kt-menu="true"
                    data-kt-menu-expand="false"
                  >
                    <Link to="/dashboard">
                      <MenuItem> {translations.DASHBOARD} </MenuItem>
                    </Link>
                    <SubMenu label={translations.VENDOR}>
                      <Link to="/vendor">
                        <MenuItem>{translations.LIST_VENDOR} </MenuItem>
                      </Link>
                      <Link to="/vendor/add">
                        <MenuItem>{translations.ADD_VENDOR} </MenuItem>
                      </Link>
                    </SubMenu>
                    <SubMenu label={translations.USER}>
                      <Link to="/user">
                        <MenuItem>{translations.LIST_USER} </MenuItem>
                      </Link>
                      <Link to="/user/add">
                        <MenuItem>{translations.ADD_USER} </MenuItem>
                      </Link>
                    </SubMenu>

                    <SubMenu label={translations.CATEGORY}>
                      <Link to="/category">
                        <MenuItem>{translations.LIST_CATEGORY}</MenuItem>
                      </Link>

                      <Link to="/wantedcategory">
                        <MenuItem>{translations.LIST_WANTED_CATEGORY}</MenuItem>
                      </Link>
                      <Link to="/category/add">
                        <MenuItem>{translations.ADD_CATEGORY}</MenuItem>
                      </Link>
                    </SubMenu>

                    <SubMenu label={translations.PRODUCT}>
                      <Link to="/product/add">
                        <MenuItem>{translations.ADD_PRODUCT}</MenuItem>
                      </Link>
                    </SubMenu>

                    <SubMenu label={translations.QUOTATION}>
                      <Link to="/quotation">
                        <MenuItem>{translations.ALL_QUOTATION}</MenuItem>
                      </Link>
                    </SubMenu>

                    <SubMenu label={translations.ORDER}>
                      <Link to="/order">
                        <MenuItem>{translations.ALL_ORDER}</MenuItem>
                      </Link>
                    </SubMenu>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
              id="kt_app_sidebar_footer"
            >
              <p
                onClick={logout}
                className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-dismiss-="click"
              >
                <span className="btn-label">{translations.LOGOUT}</span>
              </p>
            </div>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}

export default AppSidebar;
