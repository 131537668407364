import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import * as Yup from "yup";

import { useParams } from "react-router-dom";
import { UpdateProductFormValues } from "../../type/Product";
import {
  getSingleProduct,
  updateProduct,
  updateProductImage,
} from "../../services/productService";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";
import { useLanguage } from "../../context/LanguageContext";

function UpdateProduct() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const { translations } = useLanguage();
  const [uploadSuccess, setUploadSuccess] = useState("");

  const handleimageSet = (event: any) => {
    setSelectedImages(event.target.files[0]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const selectedFiles = Array.from(files);
      setSelectedImages(selectedFiles);
    }
  };
  const handleImageSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedImages.length > 1) {
      try {
        setLoading(true);

        // Use a loop to upload each selected image
        const response = await updateProductImage(productId, selectedImages);

        setUploadSuccess("Images uploaded successfully.");
        setSelectedImages([]); // Clear selected images
        setLoading(false);
      } catch (error) {
        setUploadError("Image upload failed. Please try again.");
        console.error(error);
        setLoading(false);
        setTimeout(() => {
          setUploadError("");
        }, 2000);
      }
    } else {
      setUploadError("Please select more than 2 images");
      setTimeout(() => {
        setUploadError("");
      }, 2000);
    }
  };

  const [initialValues, setInitialValues] = useState<UpdateProductFormValues>({
    productName: "",
    productDesc: "",

    // image: null,
  });
  const validationSchema = Yup.object({
    productName: Yup.string().required("Name is required"),

    productDesc: Yup.string().required("description  is required"),

    // image: You can add validation for image if needed (e.g., file type, size, etc.)
  });

  const param = useParams();
  const productId = Number(param.id);

  async function getProduct(productId: number) {
    const response = await getSingleProduct(productId);
    console.log(response.phone);
    const formattedData: UpdateProductFormValues = {
      productName: response.product_name,
      productDesc: response.desc,
    };

    setInitialValues(formattedData);
    setImageUrl(response.image_urls);
  }

  const handleSubmit = async (values: UpdateProductFormValues) => {
    try {
      setLoading(true);
      const response = await updateProduct(productId, values);
      if (response.message === "An error occurred") {
        setError("An error occurred");
      } else {
        setSuccessMessage("Updated Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProduct(productId);
  }, []);
  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8">
          <div className="card mb-4">
            <div className="card-body ">
              <div className="row">
                {initialValues.productName.length > 0 && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            id="checkout-form-wizard"
                            role="application"
                            className="wizard clearfix"
                          >
                            <div className="content clearfix">
                              <h3
                                id="checkout-form-wizard-h-0"
                                tabIndex={-1}
                                className="title current"
                              >
                                Update Product
                              </h3>
                              {successMessage && (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {successMessage}
                                </div>
                              )}
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              <div className="row g-4 mb-3">
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.NAME}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_NAME}
                                      type="text"
                                      name="productName"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="productName" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {" "}
                                      {translations.DESCRIPTION}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_DESC}
                                      type="text"
                                      name="productDesc"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="productDesc" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center my-3">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {translations.UPDATE_PRODUCT}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                )}
              </div>
            </div>
            <div
              className="content clearfix"
              style={{ width: "100%", marginLeft: "30px" }}
            >
              {uploadError && (
                <div className="alert alert-danger col-11">
                  {translations.Error}: {uploadError}
                </div>
              )}
              {uploadSuccess && (
                <div className="alert alert-success col-11">
                  {translations.SUCCESS}: {uploadSuccess}
                </div>
              )}

              {initialValues.productName.length > 0 && (
                <form
                  onSubmit={handleImageSubmit}
                  encType="multipart/form-data"
                >
                  <div className="row g-4 mb-3">
                    <div className="col-md-11">
                      {/* <div className="d-flex justify-content-center my-2">
                        {imagePreview ? (
                          <img
                            className="mt-5 border-5"
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                            alt={initialValues.productName}
                          />
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-11">
                      <div className="my-2">
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          multiple
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center my-3">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {translations.UPDATE_IMAGE}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateProduct;
