import { Link } from "react-router-dom";

import { User } from "../../type/User";

import getListOfUser, { deleteUser } from "../../services/userService";
import { useEffect, useState } from "react";

import Loader from "../../fragments/Loader";

import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { useLanguage } from "../../context/LanguageContext";

function ListUser() {
  const [user, setUser] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [successMessage, setSuccess] = useState<string>("");
  const { translations } = useLanguage();
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);

  async function init() {
    const userList = await getListOfUser();
    setUser(userList);
    const totalCount = userList.length;
    setTotalCount(totalCount);
    setError("");
    setLoader(false);
  }

  async function handleDelete(id: number) {
    const response = await deleteUser(id);
    if (response?.message) {
      setSuccess(response.message);
      init();
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    }
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>

        <div className="col-md-8 mt-10">
          <div className="card">
            <div className="card-body">
              <div className="d-md-flex gap-4 align-items-center">
                <div className="d-none d-md-flex">
                  {translations.TOTAL} {totalCount} {translations.USERS}
                </div>
                <div className="d-md-flex gap-4 align-items-center">
                  <form className="mb-3 mb-md-0"></form>
                </div>

                <div className=" " style={{ marginLeft: "667px" }}>
                  <Link
                    to="/user/add"
                    className="btn btn-primary "
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {translations.ADD_USER}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loader ? (
              <Loader />
            ) : (
              <table
                className="table table-custom table-lg mb-0"
                id="Categorys"
              >
                <thead>
                  <tr>
                    <th>{translations.ID}</th>
                    <th>{translations.IMAGE}</th>
                    <th>{translations.NAME}</th>
                    <th>{translations.EMAIL}</th>
                    <th>{translations.PHONE_NUMBER}</th>
                    <th>{translations.ACTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {user.length > 0
                    ? user.map((output, index) => (
                        <tr key={index}>
                          <td>{output.id}</td>
                          <td>
                            <img
                              src={output.image_path}
                              alt={output.image_path}
                              width={50}
                              height={50}
                            />
                          </td>
                          <td>{output.name}</td>
                          <td>{output.email}</td>
                          <td>{output.phone}</td>
                          <td className="text-end">
                            <div className="d-flex">
                              <Link to={`/user/${output.id}`}>
                                <button className="btn btn-primary btn-sm">
                                  <b>{translations.UPDATE}</b>
                                </button>
                                {/* <i className="bi bi-pencil small me-2 customIcon mx-3"></i> */}
                              </Link>

                              <button
                                onClick={() => handleDelete(output.id)}
                                className="btn btn-primary btn-sm mx-3"
                              >
                                <b>{translations.DELETE}</b>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Records Found"}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListUser;
