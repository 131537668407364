// import Header from "../../Fragments/Header";
// import Layout from "../../Fragments/Layout";
// import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UpdateUserFormValues } from "../../type/User";
import {
  getSingleUser,
  updateUser,
  updateUserImage,
} from "../../services/userService";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { useLanguage } from "../../context/LanguageContext";

function UpdateUser() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { translations } = useLanguage();
  const [uploadError] = useState<string | null>(null); // Specify the type as string | null
  const [uploadSuccess] = useState<string>("");
  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        setLoading(true);
        const response = updateUserImage(id, selectedImage);

        console.log(response);

        setSuccessMessage("Image uploaded successfully.");
        setSelectedImage(null);
        setLoading(false);
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
    }
  };

  const [initialValues, setInitialValues] = useState<UpdateUserFormValues>({
    name: "",
    email: "",
  });
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),

    email: Yup.string().email("Invalid email").required("Email is required"),

    // image: You can add validation for image if needed (e.g., file type, size, etc.)
  });

  const param = useParams();
  const id = Number(param.id);

  async function getUser(id: number) {
    const response = await getSingleUser(id);
    console.log(response.phone);
    const formattedData: UpdateUserFormValues = {
      name: response.name,
      email: response.email,
    };

    setInitialValues(formattedData);
    setImageUrl(response.image_path);
  }

  const handleSubmit = async (values: UpdateUserFormValues) => {
    try {
      setLoading(true);
      const response = await updateUser(id, values);
      if (response.message === "An error occurred") {
        setError("An error occurred");
      } else {
        setSuccessMessage("Updated Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUser(id);
  }, []);
  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8">
          <div className="card mb-4 mt-10">
            <div className="card-body ">
              <div className="row">
                {initialValues.name.length > 0 && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            id="checkout-form-wizard"
                            role="application"
                            className="wizard clearfix"
                          >
                            <div className="content clearfix">
                              <h3
                                id="checkout-form-wizard-h-0"
                                tabIndex={-1}
                                className="title current"
                              >
                                {translations.UPDATE_USER}
                              </h3>
                              {successMessage && (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {successMessage}
                                </div>
                              )}
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              <div className="row g-4 mb-3">
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.NAME}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="Enter name"
                                      type="text"
                                      name="name"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="name" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.EMAIL}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="Enter email"
                                      type="email"
                                      name="email"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="email" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center my-3">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {translations.UPDATE_USER}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                )}
              </div>
            </div>

            <div
              className="content clearfix"
              style={{ width: "100%", marginLeft: "30px" }}
            >
              {uploadError && (
                <div className="alert alert-danger">
                  {translations.Error}: {uploadError}
                </div>
              )}
              {uploadSuccess && (
                <div className="alert alert-success">
                  {translations.SUCCESS}: {uploadSuccess}
                </div>
              )}

              {initialValues.name.length > 0 && (
                <form onSubmit={handleImageSubmit}>
                  <div className="row g-4 mb-3">
                    <div className="col-md-11">
                      <div className="d-flex justify-content-center my-2">
                        {imagePreview ? (
                          <img
                            className="mt-5 border-5"
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                            alt={initialValues.name}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-11">
                      <div className="my-2">
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={(event) => {
                            handleImageChange(event);
                            handleimageSet(event);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center my-3">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {translations.UPDATE_IMAGE}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateUser;
