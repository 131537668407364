import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { CategoryFormValues } from "../../type/Category";
import { addCategory } from "../../services/categoryService";

import { useLanguage } from "../../context/LanguageContext";

const validationSchema = Yup.object({
  categoryName: Yup.string().required("category name is required"),
  mostWanted: Yup.string().required("select any value"),
  // image: You can add validation for image if needed (e.g., file type, size, etc.)
});

function AddCategory() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CategoryFormValues>({
    categoryName: "",
    mostWanted: "false",
  });
  const { translations } = useLanguage();
  const handleSubmit = async (
    values: CategoryFormValues,
    formikHelpers: FormikHelpers<CategoryFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");

      const response = await addCategory(values);
      console.log(response);
      if (response.status === 200) {
        setSuccessMessage(response?.data.message);
        setLoading(false);

        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
        setLoading(false);
      } else {
        if (response.response.data.error) {
          setLoading(false);

          setError(response.response.data.error);
        }
        if (response.response.data.message) {
          setLoading(false);

          setError(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred while adding the vendor");
    }
  };

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8">
          <div className="card mb-4 mt-10">
            <div className="card-body ">
              <div className="row">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            id="checkout-form-wizard"
                            role="application"
                            className="wizard clearfix"
                          >
                            <div className="content clearfix">
                              <h3
                                id="checkout-form-wizard-h-0"
                                tabIndex={-1}
                                className="title current"
                              >
                                {translations.ADD_CATEGORY}
                              </h3>
                              {successMessage && (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {successMessage}
                                </div>
                              )}
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              <div className="row g-4 mb-3">
                                <div className="col-md-12">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.NAME}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_NAME}
                                      type="text"
                                      name="categoryName"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="categoryName" />
                                    </div>
                                  </div>
                                </div>

                                <div className="my-2">
                                  <label>{translations.MOST_WANTED} </label>
                                  <Field
                                    className="form-control"
                                    placeholder={translations.MOST_WANTED}
                                    name="mostWanted" // Change "active" to "mostWanted" here
                                    as="select"
                                    onChange={(e: any) => {
                                      console.log(e.target.value);
                                      setFieldValue(
                                        "mostWanted",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="false">
                                      {translations.NO}
                                    </option>
                                    <option value="true">
                                      {translations.YES}
                                    </option>
                                  </Field>
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="mostWanted" />{" "}
                                    {/* Change "active" to "mostWanted" here */}
                                  </div>

                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="active" />
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center my-3">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {translations.ADD_CATEGORY}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddCategory;
