import { useEffect, useState } from "react";
import Footer from "../fragments/Footer";
import Layout from "../fragments/Layout";

import axios from "axios";
import { useLanguage } from "../context/LanguageContext";

function Dashboard() {
  const [userCount, setUserCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const { translations } = useLanguage();
  const [productCount, setProductCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);

  async function init() {
    // COUNT USERS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get-user-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUserCount(res.data.total_count);
      });
    // COUNT VENDORS
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get-all-vendors`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVendorCount(res.data.total_count);
      });
    // COUNT CATEGORY
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get-all-categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCategoryCount(res.data.total_count);
      });

    // COUNT ORDER
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get-all-quotations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setProductCount(res.data.total_count);
      });

    // COUNT ORDER
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get-order-count`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setOrdersCount(res.data.total_count);
      });
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-2">
          <Layout />
        </div>
        <div className="col-md-10">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
              <div
                id="kt_app_toolbar_container"
                className="app-container  container-xxl d-flex flex-stack "
              >
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                  <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                    {translations.DASHBOARD}
                  </h1>
                </div>
              </div>
            </div>

            <div
              id="kt_app_content"
              className="app-content  flex-column-fluid "
            >
              <div
                id="kt_app_content_container"
                className="app-container  container-xxl "
              >
                <div className="row gy-5 g-xl-10">
                  <div className="col-sm-6 col-xl-3 mb-xl-10">
                    <div className="card h-lg-100">
                      <div className="card-body d-flex justify-content-between align-items-start flex-column">
                        <div className="m-0">
                          <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </div>

                        <div className="d-flex flex-column my-7">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                            {userCount}
                          </span>

                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">
                              {translations.TOTAL_USERS}
                            </span>
                          </div>
                        </div>

                        <span className="badge badge-light-success fs-base">
                          <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3 mb-xl-10">
                    <div className="card h-lg-100">
                      <div className="card-body d-flex justify-content-between align-items-start flex-column">
                        <div className="m-0">
                          <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </div>

                        <div className="d-flex flex-column my-7">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                            {vendorCount}
                          </span>

                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">
                              {translations.TOTAL_VENDORS}
                            </span>
                          </div>
                        </div>

                        <span className="badge badge-light-success fs-base">
                          <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3 mb-xl-10">
                    <div className="card h-lg-100">
                      <div className="card-body d-flex justify-content-between align-items-start flex-column">
                        <div className="m-0">
                          <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </div>

                        <div className="d-flex flex-column my-7">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                            {categoryCount}
                          </span>

                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">
                              {translations.TOTAL_CATEGORY}
                            </span>
                          </div>
                        </div>

                        <span className="badge badge-light-success fs-base">
                          <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3 mb-xl-10">
                    <div className="card h-lg-100">
                      <div className="card-body d-flex justify-content-between align-items-start flex-column">
                        <div className="m-0">
                          <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </div>

                        <div className="d-flex flex-column my-7">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                            {productCount}
                          </span>

                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">
                              {translations.TOTAL_QUOTATIONS}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3 mb-xl-10">
                    <div className="card h-lg-100">
                      <div className="card-body d-flex justify-content-between align-items-start flex-column">
                        <div className="m-0">
                          <i className="ki-duotone ki-chart-simple fs-2hx text-gray-600">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </div>

                        <div className="d-flex flex-column my-7">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                            {ordersCount}
                          </span>

                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">
                              {translations.ORDER}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
