import { Order } from "../../type/Order";
import getListOfOrder from "../../services/orderService";
import { useEffect, useState } from "react";
import Loader from "../../fragments/Loader";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";
import SortOrder from "./SortOrder";
import { Link } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";

function ListOrder() {
  const [order, setOrder] = useState<Order[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [successMessage, setSuccess] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState<string>("In-Process");
  const [selectedOption, setSelectedOption] = useState<string>("dsc");
  const { translations } = useLanguage();
  async function init() {
    const orderList = await getListOfOrder(status);
    console.log(orderList);
    setOrder(orderList);
    const totalCount = orderList.length;
    setTotalCount(totalCount);
    setError("");
    setLoader(false);
  }

  function handleSelect(e: any) {
    setSelectedOption(e.target.value);
    setStatus(e.target.value);
    init();
  }

  useEffect(() => {
    init();
  }, [status]);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>

        <div className="col-md-8 mt-10">
          <div className="card">
            <div className="card-body">
              <div className="d-md-flex gap-4 align-items-center">
                <div className="d-none d-md-flex">
                  {translations.TOTAL} {totalCount} {translations.ORDER}
                </div>
                <div className="d-md-flex gap-4 align-items-center">
                  <form className="mb-3 mb-md-0">
                    <div className="row">
                      <SortOrder
                        handleSelect={handleSelect}
                        selectedOption={selectedOption}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loader ? (
              <Loader />
            ) : (
              <table
                className="table table-custom table-lg mb-0"
                id="Categorys"
              >
                <thead>
                  <tr>
                    <th>{translations.ID}</th>
                    <th> {translations.COMPANY_ID}</th>
                    <th>{translations.QUANTITY}</th>
                    <th>{translations.DATE}</th>
                    <th>{translations.TIME}</th>
                    <th>{translations.PRODUCT_NAME} </th>
                    <th>{translations.STATUS}</th>
                    <th>{translations.DESCRIPTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {order.length > 0
                    ? order.map((output, index) => (
                        <tr key={index}>
                          <td>{output.id}</td>
                          {/* <td>
                            <img
                              src={output.image_path}
                              alt="category"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "10px",
                              }}
                            />
                          </td> */}
                          <td>{output.company_id}</td>
                          <td>{output.quotation.quantity}</td>
                          <td>{output.quotation.date}</td>
                          <td>{output.quotation.time}</td>
                          <td>{output.quotation.product.product_name}</td>
                          <td> {output.status}</td>
                          <td>{output.quotation.product.description}</td>
                          <td className="text-end">
                            <div className="d-flex">
                              <Link to={`/order-details/${output.id}`}>
                                <button className="btn btn-primary btn-sm">
                                  <b>{translations.VIEW_ORDER} </b>
                                </button>
                                {/* <i className="bi bi-pencil small me-2 customIcon mx-3"></i> */}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Records Found"}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListOrder;
