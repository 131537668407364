import axios, { AxiosResponse } from "axios";

import { Order, OrderResponse } from "../type/Order";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfOrder(
  statusname: string
): Promise<Order[]> {
  const response: AxiosResponse<OrderResponse> = await axios.post(
    `${API_URL}/get-orders-status`,
    { status: statusname },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.quotes_list) {
    return response.data.quotes_list;
  } else {
    return [];
  }
}

export async function getSingleOrder(id: number) {
  const requestBody = { id: id };
  const response = await axios.post(
    `${API_URL}/get-order-details`,
    { id: requestBody.id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.quotation_detail;
}
