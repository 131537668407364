import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { Order } from "../../type/Order";
import { getSingleOrder } from "../../services/orderService";

import { useLanguage } from "../../context/LanguageContext";

function OrderDetails() {
  const [quotationData, setQuotationData] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { translations } = useLanguage();

  const { id } = useParams();

  useEffect(() => {
    async function fetchOrder() {
      try {
        setLoading(true);
        const response = await getSingleOrder(Number(id));
        setQuotationData(response);
        console.log("response");
        console.log(response.product);
      } catch (err) {
        setError("An error occurred while fetching category details.");
      } finally {
        setLoading(false);
      }
    }

    fetchOrder();
  }, [id]);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className="col-md-8">
          <h3 className="mt-10 mb-20">{translations.ORDER}</h3>

          <div
            className="table-responsive"
            style={{ overflow: "hidden", outline: "none" }}
            tabIndex={1}
          >
            {loading ? (
              <p>{translations.LOADING_CATEGORY}...</p>
            ) : error ? (
              <p>{error}</p>
            ) : quotationData ? (
              <div className="row g-4 mb-3">
                <div className="col-md-4">
                  <div className="my-2">
                    {/* <label className="pb-3">
                      <b> {translations.PRODUCT_PICTURE}:</b>{" "}
                      <img
                        src={`http://qoutem.com:3000/product-images/`}
                        alt="profile picture"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                      />
                    </label> */}
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.USER_DETAILS}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.USER_ID}: </b> {quotationData.user_id}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.FULL_NAME}: </b>{" "}
                      {quotationData.user.full_name}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PHONE_NUMBER}: </b>
                      {quotationData.user.phone_number}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.EMAIL}: </b>
                      {quotationData.user.email}
                    </label>
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.ORDER_DETAIL}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.ORDER_ID}: </b> {quotationData.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PRICE_ID}: </b> {quotationData.price_id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.VENDOR_NOTE}: </b>{" "}
                      {quotationData.vendor_note}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.STATUS}: </b> {quotationData.status}
                    </label>
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.PRODUCT_DETAILS}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PRODUCT_ID}: </b>
                      {quotationData.quotation.product.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.CATEGORY_ID}: </b>
                      {quotationData.quotation.product.category_id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PRODUCT_NAME}: </b>
                      {quotationData.quotation.product.product_name}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DESCRIPTION}: </b>
                      {quotationData.quotation.product.description}
                    </label>
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.QUOTATION_DETAILS}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.Quotation_Id}: </b>
                      {quotationData.quotation.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DESC}: </b>{" "}
                      {quotationData.quotation.desc}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.ADDRESS}: </b>
                      {quotationData.quotation.address}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.QUANTITY}: </b>
                      {quotationData.quotation.quantity}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.DATE}: </b>
                      {quotationData.quotation.date}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.TIME}: </b>
                      {quotationData.quotation.time}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PLACE}: </b>
                      {quotationData.quotation.place}
                    </label>
                  </div>
                </div>

                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.COMPANY_DETAILS}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.COMPANY_ID}: </b>
                      {quotationData.company.id}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.COMPANY_NAME}: </b>
                      {quotationData.company.company_name}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PHONE_NUMBER}: </b>
                      {quotationData.company.phone_number}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.ADDRESS}: </b>
                      {quotationData.company.address}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.EMAIL}: </b>
                      {quotationData.company.email}
                    </label>
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b>{translations.PROFILE_PICTURE}: </b>
                      <img
                        src={`http://qoutem.com:3000/user-images/$data.image/${quotationData.user.profile_picture}`}
                        alt="profile picture"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                      />
                    </label>
                  </div>
                </div> */}
                <div className="card mb-4 mt-10">
                  <div className="card-body">
                    <h3>{translations.PRICE_DETAILS}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b> {translations.PRICE_ID}: </b> {quotationData.price.id}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b> {translations.SHIPPING_CHARGES}: </b>{" "}
                      {quotationData.price.shipping_charges}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b> {translations.PRICE_BEFORE_TAX}: </b>{" "}
                      {quotationData.price.price_before_tax}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2">
                    <label className="pb-3">
                      <b> {translations.PRICE_AFTER_TAX}: </b>{" "}
                      {quotationData.price.price_after_tax}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <p>{translations.NO_QUOTATION_FOUND}</p>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default OrderDetails;
