import React, { createContext, useContext, useState, useEffect } from "react";

import en from "../transalation/en.json";
import ar from "../transalation/ar.json";

type Language = "en" | "ar";

interface LanguageContextType {
  language: Language;
  translations: Record<string, string>;
  changeLanguage: (newLanguage: any) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export const LanguageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [language, setLanguage] = useState<Language>("ar");
  const [translations, setTranslations] = useState<Record<string, string>>(en);

  useEffect(() => {
    const languageData: Record<Language, Record<string, string>> = {
      en,
      ar,
    };

    setTranslations(languageData[language]);
  }, [language]);

  const changeLanguage = (newLanguage: Language) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ language, translations, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
