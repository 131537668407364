import { VendorFormValues, UpdateVendorFormValues } from "../type/Vendor";
import axios, { AxiosResponse } from "axios";

import { Vendor, VendorResponse } from "../type/Vendor";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfVendor(): Promise<Vendor[]> {
  const response: AxiosResponse<VendorResponse> = await axios.get(
    `${API_URL}/get-all-vendors`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.companies_list) {
    return response.data.companies_list;
  } else {
    return [];
  }
}

export async function addVendor(vendor: VendorFormValues) {
  try {
    const response = await axios
      .post(`${API_URL}/add-vendor`, vendor, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  } catch (error) {
    return;
  }
}

export async function deleteVendor(id: number) {
  try {
    const requestBody = { id: id }; // Create the request body with the ID

    const response = await axios.post(
      `${API_URL}/delete-vendor`,
      { vendorId: requestBody.id },
      {
        // Send the request body with the ID
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Specify the content type
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete vendor");
  }
}

export async function getSingleVendor(id: number) {
  const requestBody = { id: id }; // Create the request body with the ID
  const response = await axios.post(
    `${API_URL}/get-vendor`,
    { vendorId: requestBody.id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.user_details;
}

export async function updateVendor(id: number, data: UpdateVendorFormValues) {
  const requestBody = { id: id };
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.post(
      `${API_URL}/update-vendor`,
      { vendorId: requestBody.id, ...data },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function updateVendorImage(id: number, imageFile: File) {
  const requestBody = { id: id };
  try {
    const formData = new FormData();
    formData.append("photo", imageFile);
    formData.append("id", requestBody.id.toString());

    const response = await axios.post(
      `${API_URL}/upload-vendor-photo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
