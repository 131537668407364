import { ProductFormValues, UpdateProductFormValues } from "../type/Product";
import axios, { AxiosResponse } from "axios";

import { Product, ProductResponse } from "../type/Product";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfProduct(
  categoryId: string
): Promise<Product[]> {
  const response: AxiosResponse<ProductResponse> = await axios.post(
    `${API_URL}/get-all-products`,
    { categoryId: categoryId },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  console.log("Response");
  console.log(response);
  if (response.data.products) {
    return response.data.products;
  } else {
    return [];
  }
}

export async function addProduct(product: ProductFormValues) {
  try {
    console.log(product);
    const response = await axios.post(`${API_URL}/add-product`, product, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log(response.data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getSingleProduct(id: number) {
  const requestBody = { id: id }; // Create the request body with the ID
  const response = await axios.post(
    `${API_URL}/get-product`,
    { productId: requestBody.id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.product;
}

export async function updateProduct(id: number, data: UpdateProductFormValues) {
  const requestBody = { id: id };
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    // console.log("jfdsklajfdsklajflkdsjflkdsjfdlk");
    console.log(data);

    const response = await axios.post(
      `${API_URL}/update-product`,
      { productId: requestBody.id, ...data },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function updateProductImage(id: number, imageFile: File[]) {
  const requestBody = { id: id };
  try {
    const formData = new FormData();
    for (let i = 0; i < imageFile.length; i++) {
      formData.append("photos", imageFile[i]);
    }
    formData.append("id", requestBody.id.toString());

    const response = await axios.post(
      `${API_URL}/upload-product-photo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
}
