import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { VendorFormValues } from "../../type/Vendor";

import { addVendor } from "../../services/vendorService";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { useLanguage } from "../../context/LanguageContext";
const initialValues: VendorFormValues = {
  phone: "",
  licenceNumber: "",
  address: "",
  companyName: "",
  email: "",
  password: "",
  country: "",
  city: "",
  // image: null,
};

const validationSchema = Yup.object({
  phone: Yup.string().required("Phone is required"),
  licenceNumber: Yup.string().required("Licence Number is required"),
  address: Yup.string().required("Address is required"),
  companyName: Yup.string().required("Company Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  // image: You can add validation for image if needed (e.g., file type, size, etc.)
});

function AddVendor() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { translations } = useLanguage();

  const handleSubmit = async (
    values: VendorFormValues,
    formikHelpers: FormikHelpers<VendorFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");

      const response = await addVendor(values);
      console.log(response);
      if (response.status === 200) {
        setSuccessMessage(response?.data.message);
        setLoading(false);

        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
        setLoading(false);
      } else {
        if (response.response.data.error) {
          setLoading(false);

          setError(response.response.data.error);
        }
        if (response.response.data.message) {
          setLoading(false);

          setError(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred while adding the vendor");
    }
  };

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8">
          <div className="card mb-4 mt-10">
            <div className="card-body ">
              <div className="row">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            id="checkout-form-wizard"
                            role="application"
                            className="wizard clearfix"
                          >
                            <div className="content clearfix">
                              <h3
                                id="checkout-form-wizard-h-0"
                                tabIndex={-1}
                                className="title current"
                              >
                                {translations.ADD_VENDOR}
                              </h3>
                              {successMessage && (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {successMessage}
                                </div>
                              )}
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              <div className="row g-4 mb-3">
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.PHONE_NUMBER}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={
                                        translations.ENTER_PHONE_NUMBER
                                      }
                                      type="text"
                                      name="phone"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="phone" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.LICENCE_NUMBER}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={
                                        translations.ENTER_LICENCE_NUMBER
                                      }
                                      type="text"
                                      name="licenceNumber"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="licenceNumber" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.ADDRESS}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="Enter address"
                                      type="text"
                                      name="address"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="address" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.COMPANY_NAME}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={
                                        translations.ENTER_COMPANY_NAME
                                      }
                                      type="text"
                                      name="companyName"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="companyName" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.EMAIL}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_EMAIL}
                                      type="email"
                                      name="email"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="email" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.CITY}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_CITY}
                                      type="text"
                                      name="city"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="city" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.COUNTRY}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_COUNTRY}
                                      type="text"
                                      name="country"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="country" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.PASSWORD}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={translations.ENTER_PASSWORD}
                                      type="text"
                                      name="password"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="password" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center my-3">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {translations.ADD_VENDOR}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddVendor;
