import axios, { AxiosResponse } from "axios";

import { Category, CategoryResponse } from "../type/Category";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfWantedCategory(): Promise<Category[]> {
  const response: AxiosResponse<CategoryResponse> = await axios.get(
    `${API_URL}/get-mostWantedCategories`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.product_categories) {
    return response.data.product_categories;
  } else {
    return [];
  }
}
