import { Formik, Field, Form, ErrorMessage } from "formik";
import axios, { AxiosResponse } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { LoginForm, LoginResponse } from "../type/login";
import { useAuth } from "../context/authContext";
import Cookies from "universal-cookie";

import Language from "../fragments/Language";
import { useLanguage } from "../context/LanguageContext";

const API_URL = process.env.REACT_APP_API_URL;
function Login() {
  const cookies = new Cookies();
  const initialValues: LoginForm = {
    phone: "",
    password: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Invalid number")
      .required("Required"),
    password: Yup.string().required("Required"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { translations } = useLanguage();

  const navigate = useNavigate();
  const { setLoggedIn } = useAuth();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = async (values: LoginForm) => {
    setIsLoading(true);
    setError("");
    setSuccess(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    try {
      const response: AxiosResponse<LoginResponse> = await axios.post(
        `${API_URL}/login`,
        values
      );

      const token = response.data.token;
      localStorage.setItem("token", token);
      setLoggedIn(true);
      setSuccess(true);

      cookies.set("token", token, { path: "/" });
      navigate("/dashboard");
    } catch (error: any) {
      setError(error.response?.data?.message || "Something went wrong");
    }
  };
  return (
    <>
      {/* <div className="d-flex align-items-center gap-2 gap-lg-3">
        <a href="#">
          <Language />
        </a>
      </div> */}
      <div className="d-flex flex-column flex-root justify-content-center align-items-center vh-100">
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15">
          {success && <div className="alert alert-success">{success}</div>}
          {error && <div className="alert alert-danger">{error}</div>}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="form w-100" id="kt_sign_in_form">
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  {translations.PHONE_NUMBER}
                </label>
                <Field
                  className="form-control form-control-lg form-control-solid"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter your number"
                  name="phone"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="fv-row mb-10">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    {translations.PASSWORD}
                  </label>
                  {/* <a href="" className="link-primary fs-6 fw-bolder">
                  Forgot Password ?
                </a> */}
                </div>
                <Field
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  autoComplete="off"
                  placeholder="Enter your password"
                  name="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  disabled={isLoading}
                >
                  {/* <span className="indicator-label">Login</span> */}
                  {isLoading ? "Loading" : "Log In"}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Login;
