import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";

import ListVendor from "../modules/Vendor/ListVendor";
import AddVendor from "../modules/Vendor/AddVendor";
import UpdateVendor from "../modules/Vendor/UpdateVendor";
import ListUser from "../modules/User/ListUser";
import AddUser from "../modules/User/AddUser";
import UpdateUser from "../modules/User/UpdateUser";
import ListCategory from "../modules/Category/ListCategory";
import AddCategory from "../modules/Category/AddCategory";
import UpdateCategory from "../modules/Category/UpdateCategory";
import ListWantedCategory from "../modules/Category/ListWantedCategory";
import ListProduct from "../modules/Product/ListProduct";
import AddProduct from "../modules/Product/AddProduct";
import UpdateProduct from "../modules/Product/UpdateProduct";
import ListQuotation from "../modules/Quotations/ListQuotation";
// import ListOrder from "../modules/Order/ListOrder";
import QuotationDetail from "../modules/Quotations/QuotationDetail";
import ListOrder from "../modules/Order/Listorder";
import OrderDetails from "../modules/Order/OrderDetails";

function AppRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/vendor" element={<ListVendor />} />
          <Route path="/vendor/add" element={<AddVendor />} />
          <Route path="/vendor/:id" element={<UpdateVendor />} />

          <Route path="/user" element={<ListUser />} />
          <Route path="/user/add" element={<AddUser />} />
          <Route path="/user/:id" element={<UpdateUser />} />

          <Route path="/category" element={<ListCategory />} />
          <Route path="/category/add" element={<AddCategory />} />
          <Route path="/category/:id" element={<UpdateCategory />} />
          <Route path="/wantedcategory" element={<ListWantedCategory />} />

          <Route path="/product/:id" element={<ListProduct />} />
          <Route path="/product/add" element={<AddProduct />} />
          <Route path="/product/update/:id" element={<UpdateProduct />} />

          <Route path="/quotation" element={<ListQuotation />} />
          <Route path="/quotation-details/:id" element={<QuotationDetail />} />

          <Route path="/order" element={<ListOrder />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;
