import { CategoryFormValues, UpdateCategoryFormValues } from "../type/Category";
import axios, { AxiosResponse } from "axios";

import { Category, CategoryResponse } from "../type/Category";

const API_URL = process.env.REACT_APP_API_URL;

export default async function getListOfCategory(): Promise<Category[]> {
  const response: AxiosResponse<CategoryResponse> = await axios.get(
    `${API_URL}/get-all-categories`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.product_categories) {
    return response.data.product_categories;
  } else {
    return [];
  }
}

export async function addCategory(category: CategoryFormValues) {
  try {
    const response = await axios
      .post(`${API_URL}/add-categories`, category, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  } catch (error) {
    return;
  }
}

export async function getSingleCategory(id: number) {
  const requestBody = { id: id }; // Create the request body with the ID
  const response = await axios.post(
    `${API_URL}/get-one-category`,
    { id: requestBody.id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.category;
}

export async function updateCategory(
  id: number,
  data: UpdateCategoryFormValues
) {
  const requestBody = { id: id };
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.post(
      `${API_URL}/update-categories`,
      { categoryId: requestBody.id, ...data },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return { message: "An error occurred" };
  }
}

export async function populateCategories() {
  const response = await axios.get(`${API_URL}/get-all-categories`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  if (response.data.product_categories) {
    return response.data.product_categories;
  } else {
    return [];
  }
}

export async function updateCategoryImage(id: number, imageFile: File) {
  const requestBody = { id: id };
  try {
    const formData = new FormData();
    formData.append("photo", imageFile);
    formData.append("id", requestBody.id.toString());

    const response = await axios.post(
      `${API_URL}/upload-category-photo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
