import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  getSingleCategory,
  updateCategory,
  updateCategoryImage,
} from "../../services/categoryService";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";
import { UpdateCategoryFormValues } from "../../type/Category";

import { useLanguage } from "../../context/LanguageContext";

function UpdateCategory() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadError] = useState<string | null>(null); // Specify the type as string | null
  const [uploadSuccess] = useState<string>("");
  const { translations } = useLanguage();
  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        setLoading(true);
        const response = updateCategoryImage(id, selectedImage);
        console.log(response);

        setSuccessMessage("Image uploaded successfully.");
        setSelectedImage(null);
        setLoading(false);
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
    }
  };
  const [initialValues, setInitialValues] = useState<UpdateCategoryFormValues>({
    categoryName: "",
    mostWanted: "",

    // image: null,
  });
  const validationSchema = Yup.object({
    categoryName: Yup.string().required("Name is required"),
    mostWanted: Yup.string().required("this field is required"),

    // image: You can add validation for image if needed (e.g., file type, size, etc.)
  });

  const param = useParams();
  const id = Number(param.id);

  async function getCategory(id: number) {
    const response = await getSingleCategory(id);
    console.log(response);
    const formattedData: UpdateCategoryFormValues = {
      categoryName: response.category_name,
      mostWanted: response.most_wanted,
    };

    setInitialValues(formattedData);
    setImageUrl(response.image_url);
  }

  const handleSubmit = async (values: UpdateCategoryFormValues) => {
    try {
      setLoading(true);
      const response = await updateCategory(id, values);
      if (response.message === "An error occurred") {
        setError("An error occurred");
      } else {
        setSuccessMessage("Updated Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategory(id);
  }, []);

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8 ">
          <div className="card mb-4 mt-10">
            <div className="card-body ">
              <div className="row">
                {initialValues.categoryName.length > 0 && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              id="checkout-form-wizard"
                              role="application"
                              className="wizard clearfix"
                            >
                              <div className="content clearfix">
                                <h3
                                  id="checkout-form-wizard-h-0"
                                  tabIndex={-1}
                                  className="title current"
                                >
                                  {translations.UPDATE_CATEGORY}
                                </h3>
                                {successMessage && (
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    {successMessage}
                                  </div>
                                )}
                                {errorMessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {errorMessage}
                                  </div>
                                )}

                                <div className="row g-4 mb-3">
                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        {translations.NAME}
                                      </label>
                                      <Field
                                        className="form-control"
                                        placeholder="Enter name"
                                        type="text"
                                        name="categoryName"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="categoryName" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    {" "}
                                    <div className="my-2">
                                      <label> {translations.MOST_WANTED}</label>
                                      <Field
                                        className="form-control"
                                        placeholder=" Select one option"
                                        name="mostWanted"
                                        as="select"
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            "mostWanted",
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </Field>

                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="mostWanted" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center my-3">
                                  <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    {translations.UPDATE_CATEGORY}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
            <div
              className="content clearfix"
              style={{ width: "100%", marginLeft: "30px" }}
            >
              {uploadError && (
                <div className="alert alert-danger">
                  {translations.Error}: {uploadError}
                </div>
              )}
              {uploadSuccess && (
                <div className="alert alert-success">
                  {translations.SUCCESS}: {uploadSuccess}
                </div>
              )}

              {initialValues.categoryName.length > 0 && (
                <form onSubmit={handleImageSubmit}>
                  <div className="row g-4 mb-3">
                    <div className="col-md-11">
                      {/* <div className="d-flex justify-content-center my-2">
                        {imagePreview ? (
                          <img
                            className="mt-5 border-5"
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                            }}
                            alt={initialValues.categoryName}
                          />
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-11">
                      <div className="my-2">
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={(event) => {
                            handleImageChange(event);
                            handleimageSet(event);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center my-3">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {translations.UPDATE_IMAGE}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UpdateCategory;
