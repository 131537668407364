import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import {
  ProductFormValues,
  // CategoryPopulate,
} from "../../type/Product";
import {
  addProduct,
  // populateCategories,
} from "../../services/productService";
import * as Yup from "yup";
import Multiselect from "multiselect-react-dropdown";
import { populateCategories } from "../../services/categoryService";
import Layout from "../../fragments/Layout";
import Footer from "../../fragments/Footer";

import { useLanguage } from "../../context/LanguageContext";

const initialValues: ProductFormValues = {
  productName: "",
  categoryId: 0,
  productDesc: "",
};

const validationSchema = Yup.object({
  // name: Yup.string().required("Category Name is required"),
});

function AddProduct() {
  const [product, setProduct] = useState([]);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { translations } = useLanguage();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleSelectChange() {
    const response = await populateCategories();
    console.log(response);
    if (response.length > 0) {
      setProduct(response);
    } else {
      setProduct([]);
    }
  }

  const handleSubmit = async (
    values: ProductFormValues,
    formikHelpers: FormikHelpers<ProductFormValues>
  ) => {
    try {
      console.log(values);
      setLoading(true);
      setError("");

      const response = await addProduct(values);
      console.log(response?.message);
      if (response?.message) {
        setSuccessMessage(response?.message);
        setLoading(false);
        setImagePreview(null);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm();
      } else {
        setError("An error occurred");
      }
    } catch (error) {
      setError("An error occurred while adding the Product");
    }
  };

  return (
    <>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-3">
          <Layout />
        </div>
        <div className=" col-md-8">
          <div className="card mb-4 mt-10">
            <div className="card-body ">
              <div className="row">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            id="checkout-form-wizard"
                            role="application"
                            className="wizard clearfix"
                          >
                            <div className="content clearfix">
                              <h3
                                id="checkout-form-wizard-h-0"
                                tabIndex={-1}
                                className="title current"
                              >
                                {translations.ADD_PRODUCT}
                              </h3>
                              {successMessage && (
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {successMessage}
                                </div>
                              )}
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              <div className="row g-4 mb-3">
                                <div className="col-md-12">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {translations.NAME}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={
                                        translations.ENTER_PRODUCT_NAME
                                      }
                                      type="text"
                                      name="productName"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="productName" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-4 mb-3">
                                <div className="col-md-12">
                                  <div className="my-2">
                                    <label className="pb-3">
                                      {" "}
                                      {translations.DESCRIPTION}
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder={
                                        translations.ENTER_DESCRIPTION
                                      }
                                      type="text"
                                      name="productDesc"
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="productDesc" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="my-2">
                                  <label className="pb-3">
                                    {translations.ALL_CATEGORIES}
                                  </label>
                                  <Multiselect
                                    className="form-control"
                                    options={product}
                                    displayValue="name"
                                    selectionLimit={1}
                                    onSearch={handleSelectChange}
                                    onSelect={(selectedList) => {
                                      console.log(selectedList[0].id);
                                      setFieldValue(
                                        "categoryId",
                                        selectedList[0]?.id
                                      );
                                    }}
                                    onRemove={() => {
                                      setFieldValue("categoryId", 1);
                                    }}
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="category" />
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center my-3">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {translations.ADD_PRODUCT}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddProduct;
